<template>
  <view-item title="卡券管理">
    <template #operation>
      <en-button v-if="tabs.active === 'coupon'" type="primary" @click="operation.coupon.click">新增卡券</en-button>
      <button-ajax v-if="tabs.active === 'instance' || tabs.active === 'records'" :method="operation.export.click">导出</button-ajax>

      <en-dropdown v-if="tabs.active === 'instance'" @command="operation.options.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="sms">发送短信</en-dropdown-item>
          <en-dropdown-item command="sms-batch">全部发送短信</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="卡券列表" name="coupon">
              <en-table :data="coupon.data" :height="height - 55" :loading="coupon.loading" :method="coupon.get" :paging="coupon.paging" pagination>
                <en-table-column label="操作" prop="serialNo">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponDto'] }">
                    <button-delete link :method="coupon.operation.delete.click" :params="row">删除</button-delete>
           
                  </template>
                </en-table-column>
                <en-table-column label="卡券编号" prop="serialNo">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponDto'] }">
                    <en-button type="primary" link @click="coupon.serialNo.click(row)">{{ row.serialNo }}</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="卡券名称" prop="name"></en-table-column>
                <en-table-column label="卡券类型" prop="type.message"></en-table-column>
                <en-table-column label="面值" prop="faceValue">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponDto'] }">{{ formatMoney(row.faceValue) }}</template>
                </en-table-column>
                <en-table-column label="抵扣金额">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponDto'] }">
                    {{ row.type?.discountMethod.code === 'F' ? formatMoney(row.discountValue) : '' }}
                  </template>
                </en-table-column>
                <en-table-column label="状态" prop="status.message"></en-table-column>
                <en-table-column label="创建时间" prop="preparedDatetime">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponDto'] }">{{ formatDate(row.preparedDatetime) }}</template>
                </en-table-column>
                <en-table-column label="创建人" prop="preparedBy.name"></en-table-column>
                <en-table-column label="属于网点">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallCouponDto'] }">{{ store.user.branch?.name }}</template>
                </en-table-column>
              </en-table>
            
            </en-tab-pane>

            <en-tab-pane label="券码管理" name="instance">
              <table-dynamic
                code="MALCPIFD"
                :data="instance.data"
                :loading="instance.loading"
                :height="height - 55"
                pagination
                :paging="instance.paging"
                :method="instance.get"
              >
                <template #OPERATION="{ row }: { row: EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto'] }">
                  <button-delete :method="instance.operation.verification.click" :params="row" content="确定核销吗？">核销</button-delete>
                </template>

                <template #SERIAL_NO="{ row }: { row: EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto'] }">
                  <en-button type="primary" link @click="instance.serialNo.click(row)">{{ row.serialNo }} </en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>

            <en-tab-pane label="卡券核验记录" name="records">
              <table-dynamic
                code="MCICRECFLD"
                :data="records.data"
                :loading="records.loading"
                :height="height - 55"
                pagination
                :paging="records.paging"
                :method="records.get"
              >
                <template #OPERATION="{ row }: { row: EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto'] }">
                  <en-button type="primary" link @click="records.operation.print.click(row)">打印</en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <coupon-detail v-model="couponDetail.visible" :data="couponDetail.data"></coupon-detail>

  <short-message-template v-model="sms.visible" :data="sms.data" :props="{ name: 'customerName' }"></short-message-template>
</template>

<script lang="ts">
import { ShortMessageTemplate } from '@enocloud/business'
import { EnMessage } from '@enocloud/components'
import { print, MallCheckedRecords } from '@enocloud/print'
import CouponDetail from '@client/components/coupon-detail.vue'

export default factory({
  components: { CouponDetail, ShortMessageTemplate },

  config: {
    children: {
      operation: {
        children: {
          coupon: {
            click() {
              this.couponDetail.data = null
              this.couponDetail.visible = true
            }
          },
          export: {
            click() {
              if (this.tabs.active === 'instance') {
                return this.ajax('GET /enocloud/mall/coupon/instance/export', this.records.$ajaxParams)
              } else if (this.tabs.active === 'records') {
                return this.ajax('GET /enocloud/mall/coupon/instance/checked/export', this.records.$ajaxParams)
              }
            }
          },
          options: {
            command(option: string) {
              switch (option) {
                case 'sms':
                  if (!this.instance.selection.data.length) return EnMessage.warning('请选择会员')
                  this.sms.data = this.instance.selection.data
                  this.sms.visible = true
                  break
                case 'sms-batch':
                  this.sms.data = this.instance.data
                  this.sms.visible = true
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'coupon'
      },
      coupon: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/coupon',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/mall/coupon/:couponId'
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudMallDefinitions['MallCouponDto']) {
                await this.coupon.delete({ paths: [row.id] })
                return this.coupon.get()
              }
            }
          },
          serialNo: {
            click(row: EnocloudMallDefinitions['MallCouponDto']) {
              this.couponDetail.data = Object.assign({}, row)
              this.couponDetail.visible = true
            }
          }
        }
      },
      instance: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/coupon/instance/query',
            data: 'array',
            loading: true,
            pagination: true
          },
          verification: {
            action: 'POST /enocloud/mall/coupon/instance/:couponInstanceId/verification'
          }
        },
        children: {
          selection: {
            data: [] as EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto'][],
            change(rows: EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto'][]) {}
          },
          operation: {
            verification: {
              async click(row: EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto']) {
                await this.instance.verification({ paths: [row.id] })
                return this.instance.get()
              }
            }
          },
          serialNo: {
            click(row: EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto']) {
              this.instanceDetail.data = Object.assign({}, row)
              this.instanceDetail.visible = true
            }
          }
        }
      },
      records: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/coupon/instance/checked/records',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        children: {
          operation: {
            print: {
              click(row: EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto']) {
         
                print(MallCheckedRecords, { props: { data: row } })
              }
            }
          }
        }
      },
      couponDetail: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallCouponDto'] | null
      },
      instanceDetail: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto'] | null
      },
      sms: {
        visible: false,
        data: [] as EnocloudMallDefinitions['MallCouponInstanceCheckedRecordsQueryDto'][]
      }
    }
  },

  mounted() {
    this.coupon.get()
    this.instance.get()
    this.records.get()
  }
})
</script>
